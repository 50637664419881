<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns" :columns="filteredColumns">
          <th v-for="column in filteredColumns" :key="column">{{ column }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item" :index="index">
          <td v-for="(column, colIndex) in filteredColumns" :key="colIndex">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'base-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns'
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data'
    },
    type: {
      type: String, // striped | hover
      default: '',
      description: 'Whether table is striped or hover type'
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes'
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes'
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    filteredColumns() {
      // Filtra las columnas que tienen al menos un valor en los datos
      return this.columns.filter(column =>
        this.data.some(item => this.hasValue(item, column))
      );
    }
  },
  methods: {
    hasValue(item, column) {
      // Cambié esto para comprobar si el valor existe
      return item[column.toLowerCase()] !== undefined && item[column.toLowerCase()] !== null;
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    }
  }
};
</script>

<style></style>